import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { getNextInvoiceDateMessage } from "@utils/date"
import { minTwoDigits } from "@utils/strings"
import { Row } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import React from "react"

import styles from "./style.module.less"

const PaymentComplete = ({ paymentComplete }) => {
  const { day, month, year, paymentMethod } = paymentComplete

  const description = getNextInvoiceDateMessage(month, paymentMethod)
  const monthWithZero = minTwoDigits(month)
  const dayWithZero = minTwoDigits(day)
  // console.log(day, month, year, paymentMethod)

  return (
    <div className="registration-general flex flex-column justify-center items-center mt32">
      <Icon name="PaymentComplete" className={styles.paymentIcon} />

      <div className="tc mt32 mb32">
        <span className={cx("", styles.form_title)}>
          {translate("paymentStatusTitle")}
        </span>

        <p className={cx("mt16", styles.descriptionComplete)}>
          {translate("accountCreated")}
          {/*  <span className="f14 scorpion-main">
            {translate("paymentStatusDescriptionOne")}
          </span> 
          <p className={cx(" ", styles.form_description)}>
            {translate(description, {
              day: dayWithZero,
              month: monthWithZero,
              year,
            })}
          </p>
          <p className={cx("mt16", styles.descriptionComplete)}>
            {translate("paymentStatusDescriptionTwo")}
          </p>*/}
        </p>
      </div>

      <Row justify="center" classname={styles.buttonPage}>
        <Link
          to="/login"
          className="ant-btn ant-btn-primary items-center justify-center"
          style={{ display: "flex" }}
        >
          {translate("continue")}
        </Link>
      </Row>
    </div>
  )
}

export default PaymentComplete
