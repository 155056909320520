import { CustomModalErrors } from "@components/CustomModalErrors"
import { Icon } from "@components/Icons"
import PixExpirationTimer from "@components/PixExpirationTimer"
import SuccessAnimation from "@components/SuccessAnimation"
import { SuccessModal } from "@components/SuccessModal"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { getExpirationBill } from "@utils/date"
import { formatNumberToCurrency } from "@utils/numbers"
import { getPeriodFromPlan, getPriceFromPlan } from "@utils/plans"
import { getOnlyDigits } from "@utils/strings"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Tabs,
  Tooltip,
} from "antd"
import cx from "classnames"
import { Link, navigate } from "gatsby"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { MoipValidator } from "moip-sdk-js"
import React, { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import InputMask from "react-input-mask"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"

import { resizeImage } from "../../../utils/file"

import {
  getTask,
  startTask,
  stopAllPixTasks,
} from "../../../utils/taskSchedulers"
import {
  createSignatureAndSubscriberWirecardAdapter,
  createSubscriberAnonimattaAdapter,
  errorsAdapter,
  PaymentMethodEnum,
  SubscriberStatusEnum,
} from "./adapters/registration"
import styles from "./style.module.less"

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const TabItem = ({ actualTab, tab }) => {
  if (actualTab === "card") {
    return (
      <div className="flex items-center w-100">
        <Radio value="1" checked={tab === "1"} />
        <span>{translate("creditCard")}</span>
      </div>
    )
  } else if (actualTab === "billet") {
    return (
      <div className="flex items-center w-100">
        <Radio value="2" checked={tab === "2"} />
        <span>{translate("billet")}</span>
      </div>
    )
  }
  return (
    <div className="flex items-center w-100">
      <Radio value="3" checked={tab === "3"} />
      <span>{translate("pix")}</span>
    </div>
  )
}

const SelectedPlan = ({
  selectedPlan,
  handlePageSelected,
  registerFormValues,
  handleClearPix,
  typeDiscount,
  discount,
  codeCoupon,
}) => {
  const { interval, amount } = selectedPlan

  const period = getPeriodFromPlan(interval)

  if (!codeCoupon) {
    const handleClick = (e) => {
      e.preventDefault()
      handlePageSelected({
        nextStepNumber: 2,
        plan: selectedPlan,
        values: registerFormValues,
      })
      handleClearPix()
    }

    const price = getPriceFromPlan(amount)

    return (
      <div
        className={cx(
          "flex flex-row justify-between bg-french-rose pa16 br3",
          styles.containerPriceCard
        )}
      >
        <div
          className={cx(
            "flex flex-row justify-center items-center",
            styles.pricePlan
          )}
        >
          <img className="" src={require("@images/icons/checkPrice.svg")} />
          <div className="flex flex-column">
            <span className={cx("white", styles.price)}>
              {formatNumberToCurrency(price)}
            </span>
            <span className=" white"> Plano {translate(period)}</span>
          </div>
        </div>
        <Link
          to="#"
          className={cx(" white ", styles.buttonSwitchPlan)}
          onClick={handleClick}
        >
          {translate("change")}
        </Link>
      </div>
    )
  } else {
    const handleClick = (e) => {
      e.preventDefault()
      handlePageSelected({
        nextStepNumber: 2,
        plan: selectedPlan,
        values: registerFormValues,
        coupon: {
          code: codeCoupon,
        },
      })
      handleClearPix()
    }

    if (typeDiscount === "AMOUNT") {
      const price = getPriceFromPlan(amount) - getPriceFromPlan(discount)

      return (
        <div
          className={cx(
            "flex flex-row justify-between bg-french-rose pa16 br3",
            styles.containerPriceCard
          )}
        >
          <div
            className={cx(
              "flex flex-column justify-center items-center",
              styles.pricePlan
            )}
          >
            <span className={cx("white", styles.price)}>
              {formatNumberToCurrency(price)}
            </span>
            <span className=" white">{translate(period)}</span>
          </div>
          <Link to="#" className="white pointer" onClick={handleClick}>
            {translate("change")}
          </Link>
        </div>
      )
    } else {
      const price =
        getPriceFromPlan(amount) -
        getPriceFromPlan(amount) * (getPriceFromPlan(discount) / 100)

      return (
        <div
          className={cx(
            "flex flex-row justify-between bg-french-rose pa16 br3",
            styles.containerPriceCard
          )}
        >
          <div
            className={cx(
              "flex flex-column justify-center items-center",
              styles.pricePlan
            )}
          >
            <span className={cx("white", styles.price)}>
              {formatNumberToCurrency(price)}
            </span>
            <span className=" white">{translate(period)}</span>
          </div>
          <Link
            to="#"
            className="f14 fw6 white underline mt12 pointer"
            onClick={handleClick}
          >
            {translate("change")}
          </Link>
        </div>
      )
    }
  }
}

const UserPayment = (props) => {
  const [form] = Form.useForm()

  const [tab, setTab] = useState("1")
  const [checked, setChecked] = useState(false)
  const [checkedTwo, setCheckedTwo] = useState(false)
  const [checkedPix, setCheckedPix] = useState(false)
  const [pixPayment, setPixPayment] = useState()
  const [pixTimer, setPixTimer] = useState(0)
  const [successMessage, setSuccessMessage] = useState("")
  const [codeCoupon, setCodeCoupon] = useState("")
  const [codeCouponTwo, setCodeCouponTwo] = useState("")
  const [typeCoupon, setTypeCoupon] = useState(null)
  const [valueCoupon, setValueCoupon] = useState(0)
  const [loadingCoupon, setLoadingCoupon] = useState(false)

  const prevPix = usePrevious(pixPayment)

  const isMobile = useMedia({ maxWidth: 767 })
  const isDesktop = useMedia({ minWidth: 768 })

  const intl = useIntl()

  const { registrationSubscriber, upload } = useStores()

  const { getSignedUrlS3, uploadToS3 } = upload

  const terms = process.env.AWS_TERMS
  const privacy = process.env.AWS_PRIVACY

  const clipBoardText = intl.formatMessage({ id: "copyCode" })

  const {
    createSignatureAndSubscriberWirecard,
    createSubscriberAnonimatta,
    generatePixCode,
    verifyPixPayment,
    generateCouponDiscount,
  } = registrationSubscriber

  const {
    registerFormValues,
    selectedPlan,
    setPaymentComplete,
    handlePageSelected,
    setErrors,
    errors,
    setLoading,
    loading,
    file,
    validate,
  } = props

  useEffect(() => {
    if (tab !== "3") {
      // pix tab
      handleClearPix()
    }
  }, [tab])

  useEffect(() => {
    form.setFieldsValue({ couponCard: "" })
    form.setFieldsValue({ couponBillet: "" })
    form.setFieldsValue({ couponPix: "" })
  }, [tab])

  useEffect(() => {
    // utilizado para limpar a task de pix quando o componente for desmontado
    return function cleanup() {
      handleClearPix()
    }
  }, [])

  const handleTabSelected = (key) => {
    setTab(key)
  }

  const handleCreateSignatureAndSubscriberWirecard = async ({
    paymentMethod,
    values = {},
    codeCoupon,
  }) => {
    const phoneNumber =
      getOnlyDigits(registerFormValues.codeNumber) +
      getOnlyDigits(registerFormValues.phoneNumber)
    registerFormValues.phoneNumber = phoneNumber
    const payload = {
      registerFormValues,
      selectedPlan,
      paymentFormValues: {
        paymentMethod,
        ...values,
      },
      codeCoupon,
    }

    const adaptedPayload = createSignatureAndSubscriberWirecardAdapter(payload)
    const res = await createSignatureAndSubscriberWirecard(adaptedPayload)

    return res
  }

  const handleCreateSubscriberAnonimatta = async (filePath) => {
    if (_isEmpty(registerFormValues)) {
      console.error(
        "Não foram encontrados todos os dados necessários para continuar!"
      )
      return
    }

    const adaptedPayload = createSubscriberAnonimattaAdapter({
      ...registerFormValues,
      status: SubscriberStatusEnum.OPENED,
      filePath,
    })

    const res = await createSubscriberAnonimatta(adaptedPayload)
    const errorMessage = _get(res, "message", "")

    if (!res || errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }

    return res
  }

  const handleGetSubscriberPayload = async (filePath) => {
    if (_isEmpty(registerFormValues)) {
      console.error(
        "Não foram encontrados todos os dados necessários para continuar!"
      )
      return
    }

    const adaptedPayload = createSubscriberAnonimattaAdapter({
      ...registerFormValues,
      status: SubscriberStatusEnum.INACTIVE,
      filePath,
    })

    return adaptedPayload
  }

  const startPaymentVerification = () => {
    let res = null
    const taskName = `pix-${pixPayment.txid}-${new Date().getTime()}`
    if (!getTask(taskName)) {
      startTask(
        taskName,
        async () => {
          res = await verifyPixPayment(pixPayment.txid)
          if (res && res.status === "CONCLUIDA") {
            handlePixPaymentApproved(res)
          }
        },
        10000
      )
    }
  }

  // utilizado custom hook para verificar os valores pra comecar a task de verificacao
  useEffect(() => {
    if (prevPix !== pixPayment) {
      if (pixPayment && !prevPix && pixPayment.status !== "CONCLUIDA") {
        startPaymentVerification()
      } else if (!pixPayment) {
        handleClearPix()
      }
    }
  }, [pixPayment])

  // utilizado para limpar a task de pix quando o usuario selecionar pix
  useEffect(() => {
    if (tab !== "3") {
      // pix tab
      handleClearPix()
    }
  }, [tab])

  useEffect(() => {
    // utilizado para limpar a task de pix quando o componente for desmontado
    return function cleanup() {
      handleClearPix()
    }
  }, [])

  const handleCreatePixPayment = async () => {
    // FIRST REGISTER USER
    setLoading(true)

    const params = {
      mediaPath: "images",
      fileName: file.name,
      s3Path: "subscriber",
    }

    const signedUrlRes = await getSignedUrlS3(params)

    if (!signedUrlRes) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error get signed url")
      setErrors(adaptedErrors)
      return
    }

    const { url, filePath } = signedUrlRes

    const resizedPhoto = await resizeImage(file, 140)

    const uploadToS3Res = await uploadToS3(url, resizedPhoto)

    if (!uploadToS3Res) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error file upload to S3")
      setErrors(adaptedErrors)
      return
    }

    const userPayload = await handleGetSubscriberPayload(filePath)

    // const subscribed = await handleCreateSubscriberAnonimatta(filePath)

    // // FINISH REGISTER USER

    // if (!subscribed) {
    //   setLoading(false)
    //   return
    // }
    const payment_data = {
      transaction_amount: selectedPlan.amount / 100,
      description: selectedPlan.description,
      payment_method_id: "pix",
      payer: {
        email: _get(registerFormValues, "email", ""),
        first_name: _get(registerFormValues, "fullName", ""),
        last_name: "",
        identification: {
          type: "CPF",
          number: _get(registerFormValues, "documentNumberCPF", ""),
        },
        address: {
          zip_code: registerFormValues.address.zipcode,
          street_name: registerFormValues.address.street,
          street_number: registerFormValues.address.number,
          neighborhood: registerFormValues.address.district,
          city: registerFormValues.address.city,
          federal_unit: registerFormValues.address.state,
        },
      },
      // subscriberId: subscribed.id,
      planCode: selectedPlan.code,
      subscriptionCode: "PIX-SUB",
      planIntervalUnit: selectedPlan.interval.unit,
      planIntervalLength: selectedPlan.interval.length,
      userPayload: userPayload,
    }

    const res = await generatePixCode(payment_data)
    setLoading(false)
    setPixPayment(res)
    const time = new Date()
    time.setSeconds(time.getSeconds() + 600) // 10 minutes timer
    setPixTimer(time)
  }

  const handleClearPix = () => {
    stopAllPixTasks()
    setPixPayment(undefined)
    setPixTimer(0)
  }

  const handlePixPaymentApproved = (payment) => {
    setPixPayment(payment)
    stopAllPixTasks()
    setPixTimer(0)
    setLoading(false)
    setSuccessMessage(translate("pixSuccessValidate"))
  }

  const handleCodeCoupon = (event) => {
    const value = event.target.value
    setCodeCouponTwo(value)
  }

  const handleGenerateCouponDiscount = async () => {
    setLoadingCoupon(true)
    const response = await generateCouponDiscount(codeCouponTwo)

    if (response.status === "ACTIVE") {
      const typeDiscount = _get(response, "discount.type", "")
      const valueDiscount = _get(response, "discount.value", "")
      const codeCoupon = _get(response, "code", "")

      setTypeCoupon(typeDiscount)
      setValueCoupon(valueDiscount)
      setCodeCoupon(codeCoupon)
      setSuccessMessage(translate("couponApplied"))
    }

    if (response.status === "INACTIVE") {
      setLoadingCoupon(false)
      const adaptedErrors = errorsAdapter(translate("errorCoupon"))
      setErrors(adaptedErrors)
      return
    }

    if (response.statusCode === 404) {
      setLoadingCoupon(false)
      const adaptedErrors = errorsAdapter(translate("errorCoupon"))
      setErrors(adaptedErrors)
      return
    }

    const error = _get(response, "errors", "")

    if (error) {
      setLoadingCoupon(false)
      const adaptedErrors = errorsAdapter(translate("errorCoupon"))
      setErrors(adaptedErrors)
      return
    }
    setLoadingCoupon(false)
  }

  const handleFinishForm = async (
    values,
    paymentMethod = PaymentMethodEnum.CREDIT_CARD
  ) => {
    const errorCreditCardFormValues = !paymentMethod && _isEmpty(values)
    const errorSelectedPlan = _isEmpty(selectedPlan)
    const errorRegisterFormValues = _isEmpty(registerFormValues)

    if (errorCreditCardFormValues) {
      console.error(
        "Não foram encontrados todos os dados do form para pagamento via cartão de crédito!"
      )
      return
    }

    if (errorSelectedPlan && errorRegisterFormValues) {
      console.error(
        "Não foram encontrados todos os dados necessários para continuar!"
      )
      return
    }

    setLoading(true)

    // PIX CODE TREATMENT BEGIN
    // NAO REMOVER A ORDEM DESTE TRECHO POIS O USER JÁ FOI CADASTRADO COMO SUBSCRIBER
    if (paymentMethod === PaymentMethodEnum.PIX) {
      setLoading(false)
      return
    }

    // PIX CODE TREATMENT END

    const params = {
      mediaPath: "images",
      fileName: file.name,
      s3Path: "subscriber",
    }

    const signedUrlRes = await getSignedUrlS3(params)

    if (!signedUrlRes) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error get signed url")
      setErrors(adaptedErrors)
      return
    }

    const { url, filePath } = signedUrlRes

    const resizedPhotoPix = await resizeImage(file, 140)

    const uploadToS3Res = await uploadToS3(url, resizedPhotoPix)

    if (!uploadToS3Res) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error file upload to S3")
      setErrors(adaptedErrors)
      return
    }

    const resAnonimatta = await handleCreateSubscriberAnonimatta(filePath)

    if (!resAnonimatta) {
      setLoading(false)
      return
    }

    const resWirecard = await handleCreateSignatureAndSubscriberWirecard({
      paymentMethod,
      values,
      codeCoupon,
    })

    if (resWirecard.status === 400 || resWirecard.status === 500) {
      setLoading(false)
      const adaptedErrors = errorsAdapter(resWirecard.message)
      setErrors(adaptedErrors)
      return
    }

    if (paymentMethod === PaymentMethodEnum.BILLET) {
      if (resWirecard.errors != "") {
        setLoading(false)
        const adaptedErrors = errorsAdapter(
          translate("errorCreateSignatureWirecard")
        )
        setErrors(adaptedErrors)
      } else {
        navigate("/boleto/novo-boleto")
      }
      return
    }

    const nextInvoiceDate = _get(resWirecard, "next_invoice_date", {})
    const expirationDate = _get(resWirecard, "expiration_date", {})

    const paymentComplete = !_isEmpty(nextInvoiceDate)
      ? nextInvoiceDate
      : expirationDate

    setPaymentComplete({ ...paymentComplete, paymentMethod })

    handlePageSelected({
      nextStepNumber: 4,
      plan: selectedPlan,
      values: registerFormValues,
    })
  }

  const handleCloseSuccessModal = () => {
    if (!codeCoupon) {
      setSuccessMessage("")
      navigate("/login")
    } else {
      setSuccessMessage("")
    }
  }

  const validateCardNumber = () => ({
    validator(rule, value) {
      if (value) {
        const onlyDigitsValue = value.replace(/\D/g, "")

        if (!MoipValidator.isValidNumber(onlyDigitsValue)) {
          return Promise.reject(intl.formatMessage({ id: "cardNumberError" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateNameCard = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp(
          "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        )

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorOnlyCharacters" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const validateDueDate = () => ({
    validator(rule, value) {
      if (value) {
        const month = value.split("/")[0]
        const year = value.split("/")[1]

        if (!MoipValidator.isExpiryDateValid(month, year)) {
          return Promise.reject(intl.formatMessage({ id: "invalidDate" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateCodeCard = () => ({
    validator(rule, value) {
      if (value) {
        const creditCardNumber = form.getFieldValue("cardNumber")

        if (!creditCardNumber) {
          return Promise.reject(
            intl.formatMessage({ id: "errorCardCodeNumberCreditCard" })
          )
        }

        if (!MoipValidator.isSecurityCodeValid(creditCardNumber, value)) {
          return Promise.reject(intl.formatMessage({ id: "errorCardCode" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateCheckboxTerms = () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject(
          intl.formatMessage({ id: "validateTermsAndPrivacy" })
        )
      }
      return Promise.resolve()
    },
  })

  const validateDocumentCPF = () => ({
    async validator(rule, value) {
      if (value) {
        const regexCPF = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)

        if (!regexCPF.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorDocumentNumber" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const handleChangeState = (e) => {
    const value = e.target.checked
    setChecked(value)
  }

  const handleChangeStateTwo = (ev) => {
    const value = ev.target.checked
    setCheckedTwo(value)
  }

  const handleChangeStatePix = (ev) => {
    const value = ev.target.checked
    setCheckedPix(value)
  }

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }
  const descriptionAcceptedTerms = () => {
    return (
      <div className={cx("mb24", styles.descriptionAcceptedTerms)}>
        <p>{translate("descriptionAcceptedTerms")}</p>
      </div>
    )
  }
  const termsAndPrivacy = () => {
    return (
      <>
        <span className={styles.terms}>{translate("termsAndPrivacy2")}</span>
        <a
          href={terms}
          className={styles.termsAndPrivacy}
          target="_blank"
          rel="noreferrer"
        >
          <span>{translate("term")}</span>
        </a>
        <span className={styles.terms}>{translate("and")}</span>
        <a
          href={privacy}
          className={styles.termsAndPrivacy}
          target="_blank"
          rel="noreferrer"
        >
          <span>{translate("privacy")}</span>
        </a>
      </>
    )
  }

  return (
    <div
      className={cx(
        "registration-general flex flex-column justify-center items-center",
        styles.userPayment
      )}
    >
      <div className={styles.containerPayment}>
        <div className={cx("", styles.paymentPadding)}>
          <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
            <Col span={12} xs={24} md={12} className={styles.planSection}>
              <p>{translate("paymentDescription")}</p>
              <div>
                <span className={styles.paymentInfo}>
                  {translate("paymentInfo")}
                </span>
                <span className={styles.cancelAnytime}>
                  {translate("paymentCancelAnytime")}
                </span>
              </div>
            </Col>

            <Col
              /* span={12} */ span={12}
              xs={24}
              md={12}
              className={styles.priceSection}
            >
              {isDesktop ? (
                <SelectedPlan
                  {...props}
                  discount={valueCoupon}
                  typeDiscount={typeCoupon}
                  codeCoupon={codeCoupon}
                  handleClearPix={handleClearPix}
                />
              ) : (
                <SelectedPlan
                  {...props}
                  discount={valueCoupon}
                  typeDiscount={typeCoupon}
                  codeCoupon={codeCoupon}
                  handleClearPix={handleClearPix}
                />
              )}
            </Col>
          </Row>

          {/*  {isMobile ? (
            
          ) : null} */}
        </div>

        <Tabs
          className="paymentTabs "
          defaultActiveKey={tab}
          onChange={(event) => handleTabSelected(event)}
        >
          <Tabs.TabPane tab={<TabItem actualTab="card" tab={tab} />} key="1">
            {/* <h3>
              Pagamento via cartão desabilitado temporariamente. <br /> Para
              acessar o site, realize o pagamento via Pix.
            </h3> */}
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinishForm}
              validateMessages={VALIDATE_MESSAGES}
              initialValues={{
                cardNumber: "",
                nameCard: "",
                dueDate: "",
                codeCard: "",
                documentNumberCPF: registerFormValues.documentNumberCPF,
              }}
            >
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col span={8} xs={16} md={20}>
                  <div className="flex flex-row justify-between items-center">
                    <Form.Item
                      className="w-100"
                      label={
                        <>
                          {translate("coupon")}
                          <Tooltip
                            className={styles.customLabel}
                            color="#ffffff"
                            title={intl.formatMessage({
                              id: "tooltipMessage.coupon",
                            })}
                          >
                            <Icon
                              name="TooltipInfo"
                              style={{ marginLeft: 5 }}
                            />
                          </Tooltip>
                          <span style={{ fontWeight: "400", color: "#ea4c89" }}>
                            <i>Válido apenas p/ cartão.</i>
                          </span>
                        </>
                      }
                      name="couponCard"
                    >
                      <Input
                        className="input-general"
                        onChange={(event) => handleCodeCoupon(event)}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={4} xs={8} md={4}>
                  <Form.Item
                    name="couponButtonCard"
                    label=" "
                    className="w-100"
                  >
                    <Button
                      loading={loadingCoupon}
                      className={styles.couponButton}
                      onClick={() => handleGenerateCouponDiscount()}
                    >
                      {translate("aplly")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col span={12} xs={24} md={12}>
                  <Form.Item
                    label={translate("cardNumber")}
                    name="cardNumber"
                    rules={[{ required: true }, validateCardNumber]}
                  >
                    <InputMask
                      mask="9999 9999 9999 9999 999"
                      maskChar={null}
                      className="input-general"
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} md={12}>
                  <Form.Item
                    label={translate("nameCard")}
                    name="nameCard"
                    rules={[{ required: true }, validateNameCard]}
                  >
                    <Input className="input-general" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 12, sm: 16, md: 24, lg: 24 }}>
                <Col span={4} xs={12} md={6}>
                  <Form.Item
                    label={translate("dueDate")}
                    name="dueDate"
                    rules={[{ required: true }, validateDueDate]}
                  >
                    <InputMask
                      mask="99/99"
                      maskChar={null}
                      className="input-card"
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>

                <Col span={4} xs={12} md={6}>
                  <Form.Item
                    label={translate("codeCard")}
                    name="codeCard"
                    rules={[{ required: true }, validateCodeCard]}
                  >
                    <InputMask
                      mask="9999"
                      maskChar={null}
                      className="input-card"
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} md={12}>
                  <Form.Item
                    label="CPF"
                    name="documentNumberCPF"
                    rules={[{ required: true }, validateDocumentCPF]}
                    validateTrigger="onBlur"
                  >
                    <InputMask
                      mask="999.999.999-99"
                      maskChar={null}
                      className="input-general"
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
              </Row>

              <div>
                <Form.Item
                  name="cardCheck"
                  rules={[validateCheckboxTerms]}
                  valuePropName="checked"
                >
                  <Checkbox
                    className={styles.checkBox}
                    onChange={handleChangeState}
                  >
                    {termsAndPrivacy()}
                  </Checkbox>
                </Form.Item>
              </div>
              {descriptionAcceptedTerms()}
              <Row justify="center" className="mt32">
                <Form.Item className="w-100">
                  <Button
                    className={styles.button_submit}
                    htmlType="submit"
                    loading={loading}
                    disabled={!checked}
                  >
                    {loading ? translate("processing") : translate("continue")}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Tabs.TabPane>

          {/* PIX PAYMENT */}
          <Tabs.TabPane tab={<TabItem actualTab="pix" tab={tab} />} key="3">
            <Form form={form} layout="vertical" onFinish={handleFinishForm}>
              {/* CONCORDAR COM OS TERMOS DE USO PARA GERAR O QRCODE */}
              {!pixPayment && checkedPix && (
                <p className="tc mv24">
                  <Button
                    type="primary"
                    className="button-save"
                    onClick={handleCreatePixPayment}
                    loading={loading}
                  >
                    {translate("pixGenerateQRCode")}
                  </Button>
                </p>
              )}

              {pixPayment && pixPayment.status !== "CONCLUIDA" && (
                <div className="tc flex flex-column items-center mb24">
                  <h2
                    className="text-alert-pix css-current-storage"
                    style={{ backgroundColor: "white" }}
                  >
                    Não atualize ou saia dessa página! <br /> Aguarde a
                    confirmação de pagamento.
                  </h2>
                  <p style={{ marginBottom: "30px" }}>
                    Após a confirmação do pagamento você será redirecionado
                    automaticamente.
                  </p>

                  <h2 className={styles.pixTitle}>
                    Realize o pagamento antes da expiração do código:
                  </h2>

                  <PixExpirationTimer
                    expiryTimestamp={pixTimer}
                    onExpire={handleClearPix}
                    paymentId={pixPayment.txid}
                  />

                  <img
                    alt="Pix QRCode"
                    src={`${_get(pixPayment, "imagemQrcode", "")}`}
                    className={styles.pixContainer}
                  />

                  <span className={styles.pixCopyCodeDescription}>
                    ou copie o código abaixo para seu aplicativo de internet
                    banking:
                  </span>

                  <div className={styles.pixContentCopyCode}>
                    {_get(pixPayment, "qrcode", "")}
                  </div>

                  <CopyToClipboard
                    text={_get(pixPayment, "qrcode", "")}
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <buttom className={styles.buttonCopy}>
                      <div className="flex items-center justify-center">
                        <Icon name="Clipboard" />
                        <span className="ml8">{` ${clipBoardText}`}</span>
                      </div>
                    </buttom>
                  </CopyToClipboard>

                  <h2
                    className="text-alert-pix css-current-storage"
                    style={{ backgroundColor: "white" }}
                  >
                    Não atualize ou saia dessa página! <br /> Aguarde a
                    confirmação de pagamento.
                  </h2>
                  <p style={{ marginBottom: "10px" }}>
                    Após a confirmação do pagamento você será redirecionado
                    automaticamente.
                  </p>
                </div>
              )}
              {pixPayment && pixPayment.status === "CONCLUIDA" && (
                <SuccessAnimation />
              )}

              <br />
              <div>
                <Form.Item
                  name="pixCheck"
                  rules={[validateCheckboxTerms]}
                  valuePropName="checked"
                >
                  <Checkbox
                    className={styles.checkBox}
                    onChange={handleChangeStatePix}
                  >
                    {termsAndPrivacy()}
                  </Checkbox>
                </Form.Item>
              </div>

              {descriptionAcceptedTerms()}
              <br />

              {pixPayment && pixPayment.status === "CONCLUIDA" && (
                <Row justify="center" className="mt32">
                  <Button
                    type="primary"
                    onClick={() =>
                      handleFinishForm(null, PaymentMethodEnum.PIX)
                    }
                    loading={loading}
                    disabled={
                      _get(pixPayment, "status", "") !== "CONCLUIDA" ||
                      !checkedPix
                    }
                  >
                    {translate("continue")}
                  </Button>
                </Row>
              )}
            </Form>
          </Tabs.TabPane>
        </Tabs>

        {successMessage ? (
          <SuccessModal
            visible={!!successMessage}
            description={successMessage}
            handleClose={handleCloseSuccessModal}
            buttonText={intl.formatMessage({ id: "continue" })}
          />
        ) : null}
      </div>

      <CustomModalErrors errors={errors} setErrors={setErrors} />
    </div>
  )
}

export default UserPayment
