import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { dataURLtoFile } from "@utils/file"
import { Modal } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React from "react"

import styles from "./style.module.less"

const AvatarUser = (props) => {
  const avatarArray = [
    {
      name: "avatar-001.png",
      path: require("@images/avatar/avatar-001.png"),
    },
    {
      name: "avatar-002.png",
      path: require("@images/avatar/avatar-003.png"),
    },
    {
      name: "avatar-003.png",
      path: require("@images/avatar/avatar-002.png"),
    },
    {
      name: "avatar-004.png",
      path: require("@images/avatar/avatar-004.png"),
    },
    {
      name: "avatar-005.png",
      path: require("@images/avatar/avatar-005.png"),
    },
    {
      name: "avatar-006.png",
      path: require("@images/avatar/avatar-006.png"),
    },
    {
      name: "avatar-007.png",
      path: require("@images/avatar/avatar-007.png"),
    },
    {
      name: "avatar-008.png",
      path: require("@images/avatar/avatar-008.png"),
    },
    {
      name: "avatar-009.png",
      path: require("@images/avatar/avatar-009.png"),
    },
    {
      name: "avatar-010.png",
      path: require("@images/avatar/avatar-010.png"),
    },
    {
      name: "avatar-011.png",
      path: require("@images/avatar/avatar-011.png"),
    },
    {
      name: "avatar-012.png",
      path: require("@images/avatar/avatar-012.png"),
    },
  ]
  const { visible, handleClose, handleAvatar } = props

  const handleClick = async (name, path) => {
    const file = dataURLtoFile(path, name)
    handleAvatar(name, path, file)
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      onCancel={handleClose}
      className="subscriber-avatar"
      closeIcon={<Icon name="CloseModal" />}
    >
      <div className={cx("tc", styles.modalContainerAvatar)}>
        <span className="f20 fw5 mine-shaft">{translate("avatars")}</span>
        <p>
          <span className="f14 normal">{translate("avatarsDescription")}</span>
        </p>
      </div>

      <div className="flex flex-row flex-wrap justify-around mt24">
        {_map(avatarArray, ({ name, path }) => {
          return (
            <div
              key={name}
              className={styles.avatarBottom}
              onClick={() => handleClick(name, path)}
            >
              <img alt="" src={path} className={styles.avatarUser} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default AvatarUser
