import { LeftOutlined } from "@ant-design/icons"
import { CustomModalErrors } from "@components/CustomModalErrors"
import { SuccessModal } from "@components/SuccessModal"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
// import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import PaymentComplete from "@modules/Registration/SubscriberRegistration/PaymentComplete"
import RegistrationAddress from "@modules/Registration/SubscriberRegistration/RegistrationAddress"
import RegistrationUser from "@modules/Registration/SubscriberRegistration/RegistrationUser"
import UserPayment from "@modules/Registration/SubscriberRegistration/UserPayment"
import UserPlan from "@modules/Registration/SubscriberRegistration/UserPlan"
import translate from "@src/i18n"
import { getActivePlans } from "@utils/plans"
import { Button, Divider, Steps, Tooltip } from "antd"
import cx from "classnames"
import { Link, navigate } from "gatsby"
import _get from "lodash/get"
import _map from "lodash/map"
import _isEmpty from "lodash/isEmpty"
import _uniqBy from "lodash/uniqBy"
import React, { useCallback, useEffect, useState } from "react"
import { resizeImage } from "../../utils/file"
import { Helmet } from "react-helmet"
import {
  createSubscriberAnonimattaAdapterNew,
  errorsAdapter,
  SubscriberStatusEnum,
} from "@modules/Registration/SubscriberRegistration/adapters/registration"
import SvgCloseModal from "../../components/Icons/components/CloseModal"
import styles from "./style.module.less"
import { useMedia } from "react-use-media"
// import { Icon } from "@components/Icons"
// import IconRadius from "@components/iconRadius"

const SubscriberRegistration = ({ location }) => {
  const [current, setCurrent] = useState(0)
  const [paymentComplete, setPaymentComplete] = useState({})
  const [registerFormValues, setRegisterFormValues] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(false)
  const [plans, setPlans] = useState([])
  const [addressViaCep, setAddressViaCep] = useState([])
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [areaCode, setAreaCode] = useState([])
  const [countries, setCountries] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [file, setFile] = useState(null)
  const [successMessage, setSuccessMessage] = useState("")
  const [stepStatus, setStepStatus] = useState({
    user: { status: "process", visited: true },
    address: { status: "wait", visited: false },
    plan: { status: "wait", visited: false },
    payment: { status: "wait", visited: false },
    success: { status: "wait", visited: false },
  })
  const isMobile = useMedia({ maxWidth: 767 })
  const { registrationSubscriber, registrationAffiliated, upload } = useStores()
  const { getAllStates, getAllCities, getAllCountriesAreaCode } =
    registrationAffiliated
  const { listPlans, searchAddressViaCep, validate, newSubscriberAnonimatta } =
    registrationSubscriber
  const { getSignedUrlS3, uploadToS3 } = upload

  useEffect(() => {
    setIsClient(true)
    handleGetPlans()
    handleGetAllStates()
    handleGetAllCountriesAreaCode()
  }, [])

  const handleGetPlans = useCallback(async () => {
    const response = await listPlans()
    const plans = _get(response, "plans", [])
    const activePlans = getActivePlans(plans)

    setPlans(activePlans)
  })

  const handleGetAllCities = useCallback(async (props) => {
    const response = await getAllCities(props)

    const listCities = _map(response, (item) => ({
      id: item.id,
      name: item.nome,
    }))

    listCities.sort((a, b) => a.name.localeCompare(b.name))

    setCities(listCities)
  }, [])

  const handleSearchAddressViaCep = useCallback(async (addressViaCep) => {
    const response = await searchAddressViaCep(addressViaCep)

    if (_get(response, "error.message", "")) {
      const adaptedErrors = errorsAdapter(_get(response, "error.message", ""))
      setErrors(adaptedErrors)
    }

    setAddressViaCep(response)
  }, [])

  const handleGetAllStates = useCallback(async () => {
    const response = await getAllStates()

    const listStates = _map(response, (item) => ({
      id: item.id,
      sigla: item.sigla,
    }))

    listStates.sort((a, b) => a.sigla.localeCompare(b.sigla))

    setStates(listStates)
  }, [])

  const handleGetAllCountriesAreaCode = async () => {
    const response = await getAllCountriesAreaCode()
    const arrayDataAreaCode = []
    const arrayDataCountries = []

    _map(response, (item) => {
      const dataAreaCode = { id: item.id, code: item.areaCode }
      const dataCountries = { id: item.id, name: item.nameCountry }

      arrayDataAreaCode.push(dataAreaCode)
      arrayDataCountries.push(dataCountries)
    })

    const uniqByAreaCode = _uniqBy(arrayDataAreaCode, "code")
    uniqByAreaCode.sort((a, b) => {
      return a.code - b.code
    })

    setAreaCode(uniqByAreaCode)
    setCountries(arrayDataCountries)
  }

  const handlePageSelected = ({ nextStepNumber, values, plan }) => {
    setCurrent(nextStepNumber)
    setRegisterFormValues(values)
    setSelectedPlan(plan)

    if (nextStepNumber === 0) {
      setStepStatus({
        ...stepStatus,
        user: { status: "process", visited: true },
        address: { ...stepStatus.user, status: "wait" },
        plan: { ...stepStatus.plan, status: "wait" },
        payment: { ...stepStatus.payment, status: "wait" },
      })
    }

    if (nextStepNumber === 1) {
      setStepStatus({
        ...stepStatus,
        user: { ...stepStatus.user, status: "finish" },
        address: { status: "process", visited: true },
        plan: { ...stepStatus.plan, status: "wait" },
        payment: { ...stepStatus.payment, status: "wait" },
      })
    }

    if (nextStepNumber === 2) {
      setStepStatus({
        ...stepStatus,
        user: { ...stepStatus.user, status: "finish" },
        address: { ...stepStatus.address, status: "finish" },
        plan: { status: "process", visited: true },
        payment: { ...stepStatus.payment, status: "wait" },
      })
    }

    if (nextStepNumber === 3) {
      setStepStatus({
        ...stepStatus,
        user: { ...stepStatus.user, status: "finish" },
        address: { ...stepStatus.address, status: "finish" },
        plan: { ...stepStatus.plan, status: "finish" },
        payment: { status: "process", visited: true },
      })
    }

    if (nextStepNumber === 4) {
      setStepStatus({
        ...stepStatus,
        user: { ...stepStatus.user, status: "finish" },
        address: { ...stepStatus.address, status: "finish" },
        plan: { ...stepStatus.plan, status: "finish" },
        payment: { ...stepStatus.payment, status: "finish" },
      })
    }
  }

  const handleChangeStep = (currentSelected) => {
    handlePageSelected({
      nextStepNumber: currentSelected,
      values: registerFormValues,
      plan: selectedPlan,
    })
  }

  const handleFinishNewAccount = async (props) => {
    if (_isEmpty(props)) {
      console.error(
        "Não foram encontrados todos os dados necessários para continuar!"
      )
      return
    }

    setLoading(true)

    const params = {
      mediaPath: "images",
      fileName: file.name,
      s3Path: "subscriber",
    }
    const signedUrlRes = await getSignedUrlS3(params)

    if (!signedUrlRes) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error get signed url")
      setErrors(adaptedErrors)
      return
    }

    const { url, filePath } = signedUrlRes
    const resizedPhoto = await resizeImage(file, 140)
    const uploadToS3Res = await uploadToS3(url, resizedPhoto)

    if (!uploadToS3Res) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error file upload to S3")
      setErrors(adaptedErrors)
      return
    }

    const adaptedPayload = createSubscriberAnonimattaAdapterNew({
      ...props,
      status: SubscriberStatusEnum.OPENED,
      filePath,
    })
    const response = await newSubscriberAnonimatta(adaptedPayload)
    const errorMessage = _get(response, "message", "")

    if (!response || errorMessage) {
      setLoading(false)
      const errorMessage = _get(response, "message", "")
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    } else {
      setLoading(false)
      setSuccessMessage(translate("createAccountNew"))
    }
  }

  const handleCloseSuccessModal = () => {
    setSuccessMessage("")
    navigate("/login")
  }

  if (!isClient) return null

  const renderPageTitle = (current) => {
    const defaultTitle = (
      <div className={cx("tc mt48 mb32", styles.notCenter)}>
        <div className={styles.container_title}>
          <Link to="/">
            <button
              title="Close (Esc)"
              type="button"
              className={styles.buttonClose}
            >
              <SvgCloseModal />
            </button>
          </Link>
          <div>
            <div className={styles.pageTitle}>
              <h3>{translate("titleSubscriber")}</h3>
            </div>
            <p className={cx(styles.textContainer, { tc: !isMobile })}>
              <Link to="/login" className={styles.loginAnAccount}>
                {translate("titleUserLogin")}
              </Link>
              <span className="f14 normal scorpion-main">
                {translate("titleUserDescriptionSubCreate")}
              </span>
              <Link
                to="/cadastro-criador"
                className={cx("f14 fw5 kimberly ", styles.creatAnAccount)}
              >
                {translate("titleSubscriberDescriptionSub")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    )

    switch (current) {
      case 0:
        return defaultTitle

      case 1:
        return defaultTitle

      case 2:
        return (
          <div className={cx("tc mt48 mb32", styles.notCenter)}>
            <div className={cx("", styles.container_payment_title)}>
              <Link to="/">
                <button
                  title="Close (Esc)"
                  type="button"
                  className={styles.buttonClose}
                >
                  <SvgCloseModal />
                </button>
              </Link>
              <h3>{translate("planUserTitle")}</h3>
              <p>{translate("paymentWithCardOrPix")}</p>
            </div>
          </div>
        )
      case 3:
        return (
          <div className={cx("tc mt48 mb32", styles.notCenter)}>
            <div className={cx("", styles.container_payment_title)}>
              <Link to="/">
                <button
                  title="Close (Esc)"
                  type="button"
                  className={styles.buttonClose}
                >
                  <SvgCloseModal />
                </button>
              </Link>
              <h3>{translate("payment")}</h3>
              <p>{translate("paymentWithCardOrPix")}</p>
            </div>
          </div>
        )

      default:
        return
    }
  }

  const renderPageItem = (current) => {
    switch (current) {
      case 0:
        return (
          <RegistrationUser
            location={location}
            handlePageSelected={handlePageSelected}
            countries={countries}
            areaCode={areaCode}
            setFile={setFile}
            registerFormValues={registerFormValues}
            selectedPlan={selectedPlan}
            validate={validate}
            onFinish={handleFinishNewAccount}
          />
        )
      case 1:
        return (
          <RegistrationAddress
            handlePageSelected={handlePageSelected}
            states={states}
            cities={cities}
            handleGetAllCities={handleGetAllCities}
            handleSearchAddressViaCep={handleSearchAddressViaCep}
            addressViaCep={addressViaCep}
            registerFormValues={registerFormValues}
            selectedPlan={selectedPlan}
          />
        )
      case 2:
        return (
          <UserPlan
            handlePageSelected={handlePageSelected}
            registerFormValues={registerFormValues}
            plans={plans}
          />
        )
      case 3:
        return (
          <UserPayment
            handlePageSelected={handlePageSelected}
            selectedPlan={selectedPlan}
            registerFormValues={registerFormValues}
            setPaymentComplete={setPaymentComplete}
            setErrors={setErrors}
            errors={errors}
            setLoading={setLoading}
            loading={loading}
            file={file}
            validate={validate}
          />
        )
      case 4:
        return (
          <PaymentComplete
            handlePageSelected={handlePageSelected}
            paymentComplete={paymentComplete}
          />
        )
      default:
        return
    }
  }

  return (
    <TranslateWrapper>
      <Helmet>
        <title>Nova conta (assinante) - Anonimatta</title>
      </Helmet>
      <div
        className={cx(
          "subscriber-registration mc center",
          styles.paddingSubscriber
        )}
      >
        {renderPageTitle(current)}
        {/* {current !== 4 ? (
          <div className="flex justify-center" styles={{ marginTop: 20 }}>
            <Steps current={current} onChange={handleChangeStep}>
              <Steps.Step
                disabled={!stepStatus.user.visited}
                icon={
                  stepStatus.user.status === "wait" ? (
                    <Icon name="CustomUserWait" />
                  ) : stepStatus.user.status === "process" ? (
                    <IconRadius icon="CustomUserFinish" pr={60} />
                  ) : (
                    <Icon name="CustomUserFinish" />
                  )
                }
              />
              <Steps.Step
                disabled={!stepStatus.address.visited}
                icon={
                  stepStatus.address.status === "wait" ? (
                    <Icon name="HomeWait" />
                  ) : stepStatus.address.status === "process" ? (
                    <IconRadius icon="HomeFinish" pr={60} />
                  ) : (
                    <Icon name="HomeFinish" />
                  )
                }
              />
              <Steps.Step
                disabled={!stepStatus.plan.visited}
                icon={
                  stepStatus.plan.status === "wait" ? (
                    <Icon name="ContainerWait" />
                  ) : stepStatus.plan.status === "process" ? (
                    <IconRadius icon="ContainerFinish" pr={60} />
                  ) : (
                    <Icon name="ContainerFinish" />
                  )
                }
              />
              <Steps.Step
                disabled={!stepStatus.payment.visited}
                icon={
                  stepStatus.payment.status === "wait" ? (
                    <Icon name="CreditCardWait" />
                  ) : stepStatus.payment.status === "process" ? (
                    <IconRadius icon="CreditCardFinish" pr={60} />
                  ) : (
                    <Icon name="CreditCardFinish" />
                  )
                }
              />
              <Steps.Step
                  disabled={!stepStatus.success.visited}
                  icon={
                    stepStatus.success.status === "wait" ? (
                      <Icon name="CheckWait" />
                    ) : stepStatus.success.status === "process" ? (
                      <IconRadius icon="CheckFinish" pr={60} />
                    ) : (
                      <Icon name="CheckFinish" />
                    )
                  }
                />
            </Steps>
          </div>
        ) : null
      } */}
        {renderPageItem(current)}
      </div>
      {successMessage ? (
        <SuccessModal
          visible={!!successMessage}
          description={successMessage}
          handleClose={handleCloseSuccessModal}
          buttonText={translate("continue")}
        />
      ) : null}
      <CustomModalErrors errors={errors} setErrors={setErrors} />
    </TranslateWrapper>
  )
}

export default SubscriberRegistration
