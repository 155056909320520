import CustomModal from "@components/CustomModal"
import { CustomModalErrors } from "@components/CustomModalErrors"
import { Icon } from "@components/Icons"
import { SuccessModal } from "@components/SuccessModal"
import { SuccessModalStylized } from "@components/SuccessModalStylized"
import { useStores } from "@hooks/use-stores"
import AuthStep from "@modules/MyAccount/ForgotPassword/components/AuthStep"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { getOnlyDigits, maskPhone } from "@utils/strings"
import { validateCPF } from "@utils/validate"
import {
  Avatar,
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd"
import locale from "antd/lib/locale/pt_BR"
import cx from "classnames"
import { Link } from "gatsby"
import _map from "lodash/map"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import InputMask from "react-input-mask"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"

import AvatarUser from "./AvatarUser"
import styles from "./style.module.less"

const RegistrationUser = (props) => {
  const [photo, setPhoto] = useState()
  const [progress, setProgress] = useState(33)
  const [documentType, setDocumentType] = useState("CPF")
  const [visible, setVisible] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(null)
  const [visibleValidatePhoneNumber, setVisibleValidatePhoneNumber] =
    useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [errors, setErrors] = useState([])
  const [verifiedPhone, setVerifiedPhone] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const uploadRef = useRef(null)

  const intl = useIntl()

  const [form] = Form.useForm()

  const isMobile = useMedia({ maxWidth: 767 })
  const mobile = useMedia({ minWidth: 150, maxWidth: 1023 })
  const tablet = useMedia({ minWidth: 768 })
  const tabletDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 })
  const desktop = useMedia({ minWidth: 1920 })

  const { authSite } = useStores()

  const { sendCode } = authSite

  const {
    location,
    handlePageSelected,
    areaCode,
    setFile,
    registerFormValues,
    selectedPlan,
    validate,
    onFinish,
  } = props

  const progressBar = {
    33: {
      color: "exception",
      text: intl.formatMessage({ id: "bad" }),
    },
    66: {
      color: "active",
      text: intl.formatMessage({ id: "medium" }),
    },
    100: {
      color: "success",
      text: intl.formatMessage({ id: "excelent" }),
    },
  }

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }

  useEffect(() => {
    if (location.state && location.state.phoneNumber) {
      form.setFieldsValue({ phoneNumber: location.state.phoneNumber })
      setPhone(location.state.phoneNumber)
      handleResetStep()
    }

    if (registerFormValues.phoneNumber) {
      form.setFieldsValue({ phoneNumber: registerFormValues.phoneNumber })
      setPhone(registerFormValues.phoneNumber)
      setVerifiedPhone(true)
    }

    if (registerFormValues.avatar) {
      form.setFieldsValue({ avatar: registerFormValues.avatar })
      setPhoto(registerFormValues.avatar.data)
    }

    if (registerFormValues.newPassword) {
      progressCount(registerFormValues.newPassword)
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({ phoneNumber: maskPhone(phone) })
  }, [phone])

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreviewPhoto = async (ev) => {
    if (ev) {
      setFile(ev.file.originFileObj)
      const photoBase64 = await getBase64(ev.file.originFileObj)
      form.setFieldsValue({ avatar: { name: ev.file.name, data: photoBase64 } })
      setPhoto(photoBase64)
    }
  }

  const handleAvatarChoose = (imageName, imageBase64, file) => {
    form.setFieldsValue({ avatar: { name: imageName, data: imageBase64 } })
    setPhoto(imageBase64)
    setVisible(false)
    setFile(file)
  }

  const handleChangeIdentityDocument = (value) => {
    setDocumentType(value)
    form.setFieldsValue({ identityDocument: value })
  }

  const handleBlurPhoneNumber = () => {
    const codeNumber = form.getFieldValue("codeNumber")
    const formPhone = form.getFieldValue("phoneNumber")

    const regexPhoneNumber = new RegExp(
      /^(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{4})-?(\d{4}))$/
    )
    // const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{4,5})-(\d{4})$/) // para wpp c/ fixo

    if (formPhone && regexPhoneNumber.test(formPhone)) {
      const formPhoneNumber = `${codeNumber} ${formPhone}`

      sendCode({ phone: getOnlyDigits(formPhoneNumber) })

      setPhoneNumber(formPhoneNumber)
      setVisibleValidatePhoneNumber(true)
    }
  }

  const handleResetStep = () => {
    setVisibleValidatePhoneNumber(false)
    form.getFieldInstance("phoneNumber").input.focus()
  }

  const handleSuccessOnlyValidate = () => {
    setVisibleValidatePhoneNumber(false)
    setVerifiedPhone(true)
    setSuccessMessage(translate("validatePhoneSuccess"))
  }

  const handleErrorOnlyValidate = () => {
    setVerifiedPhone(false)
  }

  const handleChangePhoneNumber = (e) => {
    let value = e.target.value
    setPhone(value)
    setVerifiedPhone(false)
  }

  const handleFinishForm = (values) => {
    if (!verifiedPhone) {
      setErrors(errorsAdapter(translate("validatePhoneError")))
      return
    }

    handlePageSelected({
      nextStepNumber: 1,
      values: {
        ...registerFormValues,
        ...values,
        dateOfBirth: moment(values.dateOfBirth, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
      },
      plan: selectedPlan,
    })

    setLoadingButton(false)
  }

  // Nova função para registro de novo assinante.
  const handleFinishFormCreate = (values) => {
    if (!verifiedPhone) {
      setErrors(errorsAdapter(translate("validatePhoneError")))
      return
    }

    const body = {
      codeNumber: values?.codeNumber,
      dateOfBirth: moment(values?.dateOfBirth, "DD/MM/YYYY").format(
        "DD/MM/YYYY"
      ),
      documentNumberCPF: values?.documentNumberCPF,
      email: values?.email,
      fullName: values?.fullName,
      identityDocument: values?.identityDocument,
      newPassword: values?.newPassword,
      nickname: values?.nickname,
      phoneNumber: values?.phoneNumber,
      repeatPassword: values?.repeatPassword,
      username: values?.username,
    }

    onFinish(body)

    setLoadingButton(false)
  }

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value) {
        if (getFieldValue("newPassword") !== value) {
          return Promise.reject(intl.formatMessage({ id: "passwordReject" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateFullName = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp(
          "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        )

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorOnlyCharacters" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const validateNickname = () => ({
    async validator(rule, value) {
      if (value) {
        const regexNickname = new RegExp(/^[a-zA-Zç\s]+$/)

        if (!regexNickname.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorNickname" }))
        }
      }
      return Promise.resolve()
    },
  })

  const validateUsername = () => ({
    async validator(rule, value) {
      if (value) {
        const regexUsername = new RegExp(/^[a-z]+$/)

        if (!regexUsername.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorUsername" }))
        }

        if (value.length < 3) {
          return Promise.reject(intl.formatMessage({ id: "minCharacter_3" }))
        }

        const unique = await validate({
          username: value,
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateUsernameUnique" })
          )
        }
      }
      return Promise.resolve()
    },
  })

  const validateDate = () => ({
    validator(rule, value) {
      const dateString = moment(value, "DD/MM/YYYY")
      const validDate = dateString.isValid()
      if (value) {
        const birthYear = dateString.year()
        if (!validDate) {
          return Promise.reject(intl.formatMessage({ id: "invalidDate" }))
        }

        if (birthYear < 1900) {
          return Promise.reject(intl.formatMessage({ id: "errorYear" }))
        }

        if (birthYear > moment().get("year")) {
          return Promise.reject(intl.formatMessage({ id: "errorCurrentYear" }))
        }

        if (moment().diff(value, "years") < 18) {
          return Promise.reject(intl.formatMessage({ id: "ageValidation" }))
        }
      }
      return Promise.resolve()
    },
  })

  const validateDocumentCPF = () => ({
    async validator(rule, value) {
      if (value) {
        const regexCPF = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)

        const isValid = validateCPF(value)
        if (!regexCPF.test(value) || !isValid) {
          return Promise.reject(
            intl.formatMessage({ id: "errorDocumentNumber" })
          )
        }

        const unique = await validate({
          documentNumber: value,
          documentType: "cpf",
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateDocumentNumberUnique" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const validatePhoneNumber = () => ({
    async validator(rule, value) {
      if (value) {
        value = maskPhone(value)

        const regexPhoneNumber = new RegExp(
          /^(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/
        )
        // const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{4,5})-(\d{4})$/) // para wpp c/ fixo

        if (!regexPhoneNumber.test(value)) {
          setPhoneError(intl.formatMessage({ id: "errorPhoneNumber" }))
          return Promise.reject(intl.formatMessage({ id: "errorPhoneNumber" }))
        }

        const unique = await validate({ phoneNumber: value.replace(/\D/g, "") })

        if (!unique) {
          setPhoneError(intl.formatMessage({ id: "validatePhoneUnique" }))
          return Promise.reject(
            intl.formatMessage({ id: "validatePhoneUnique" })
          )
        }

        if (!verifiedPhone) {
          handleBlurPhoneNumber()
        }

        setPhoneError(null)
        return Promise.resolve()
      }

      setPhoneError("Campo obrigatório")
      return Promise.reject()
    },
  })

  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const regexEmailOnlyCharacters = new RegExp(
          "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ@. ]+$"
        )
        const regexEmailAddress = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )

        if (!regexEmailOnlyCharacters.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorOnlyCharacters" })
          )
        }

        if (!regexEmailAddress.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorEmail" }))
        }

        const unique = await validate({ email: value })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateEmailUnique" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const progressCount = (value) => {
    const passwordLength = value.length

    if (passwordLength <= 3) {
      setProgress(33)
      return
    }

    if (passwordLength <= 6) {
      setProgress(66)
      return
    }

    setProgress(100)
    return
  }

  return (
    <>
      <Form
        form={form}
        className={styles.formWidth}
        layout="vertical"
        validateMessages={VALIDATE_MESSAGES}
        onFinish={
          // handleFinishForm
          handleFinishFormCreate
        }
        onFinishFailed={() => setLoadingButton(false)}
        autoComplete="off"
        initialValues={{
          codeNumber: "+55",
          identityDocument: documentType,
          ...registerFormValues,
          dateOfBirth: registerFormValues.dateOfBirth
            ? moment(registerFormValues.dateOfBirth, "DD/MM/YYYY")
            : null,
        }}
      >
        <div
          className={cx(
            "registration-general flex flex-row justify-center",
            styles.containerRegistration
          )}
        >
          {!mobile ? (
            <Col span={12} xs={24} md={8} className={styles.spaceItems}>
              <Form.Item name="avatar" rules={[{ required: true }]}>
                <div
                  className={
                    documentType !== "Outros"
                      ? cx("tc", styles.photoContainer)
                      : cx("tc", styles.photoContainerTwo)
                  }
                >
                  <span className="f20 fw5 mine-shaft">
                    {translate("profilePhoto")}
                  </span>
                  <div>
                    <span className="f14 normal scorpion-main">
                      {translate("choosePhoto")}
                    </span>
                  </div>
                  <div className="flex justify-center">
                    {photo ? (
                      <Avatar src={photo} className={styles.photoAvatar} />
                    ) : (
                      <div
                        className={cx(
                          "bg-kimberly mt12 mb12",
                          styles.photoEmpty
                        )}
                      />
                    )}
                  </div>
                  <div>
                    <Upload
                      ref={uploadRef}
                      showUploadList={false}
                      onChange={handlePreviewPhoto}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok")
                        }, 0)
                      }}
                    >
                      <Button type="primary" className="upload-photo">
                        {photo ? translate("changePhoto") : translate("upload")}
                      </Button>
                    </Upload>
                  </div>
                  <p className="mt12">
                    <span className="f14 normal scorpion-main">
                      {translate("or")}
                    </span>
                    <Link
                      to="#"
                      className={cx("f14 fw6 pointer", styles.orUseAvatar)}
                      onClick={(e) => {
                        e.preventDefault()
                        setVisible(true)
                      }}
                    >
                      {translate("avatarUse")}
                    </Link>
                  </p>
                </div>
              </Form.Item>
            </Col>
          ) : null}

          <Col
            span={12}
            xs={24}
            md={mobile ? 24 : tabletDesktop ? 14 : desktop ? 12 : 12}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={12} xs={24} md={12}>
                <div className="flex flex-row">
                  <div className="w-33">
                    <div>
                      <div className={styles.labelNickname}>
                        <label
                          className={cx(
                            "scorpion-main",
                            styles.customLabel,
                            styles.customLabelRequired
                          )}
                        >
                          País
                        </label>
                      </div>
                      <Form.Item name="codeNumber" rules={[{ required: true }]}>
                        <Select
                          // showSearch
                          className="selectGeneral"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          notFoundContent={
                            areaCode && (
                              <div>{intl.formatMessage({ id: "noData" })}</div>
                            )
                          }
                          loading={!areaCode}
                        >
                          {_map(areaCode, (item) => (
                            <Select.Option
                              key={item.id}
                              value={item.code}
                            >{`+${item.code}`}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className={cx("w-100", styles.spacePhone)}>
                    <div>
                      <div className={styles.labelNickname}>
                        <label
                          className={cx(
                            "scorpion-main",
                            styles.customLabel,
                            styles.customLabelRequired
                          )}
                        >
                          {translate("whatsapp")}

                          <Tooltip
                            color="#ffffff"
                            title={intl.formatMessage({
                              id: "tooltipMessage.whatsapp",
                            })}
                            //title={translate("whatsappInfo")}
                          >
                            <Icon
                              name="TooltipInfo"
                              style={{ marginLeft: 5 }}
                            />
                          </Tooltip>
                        </label>
                      </div>
                      <Form.Item
                        name="phoneNumber"
                        rules={[{ required: true }, validatePhoneNumber]}
                        validateStatus={phoneError && "error"}
                        help={phoneError}
                        validateTrigger="onBlur"
                      >
                        <Input
                          value={phone}
                          onChange={handleChangePhoneNumber}
                          /*  placeholder={intl.formatMessage({ id: "typePhone" })} */
                          className="input-general-phone"
                          disabled={loading}
                          maxLength={15}
                          validateTrigger="onBlur"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("email")}
                  name="email"
                  rules={[{ required: true }, validateEmail]}
                  validateTrigger="onBlur"
                >
                  <Input
                    className="input-general"
                    /* placeholder={intl.formatMessage({ id: "typeEmail" })} */
                    type="email"
                  />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("fullName")}
                  name="fullName"
                  rules={[{ required: true }, validateFullName]}
                >
                  <Input
                    className="input-general"
                    /*  placeholder={intl.formatMessage({ id: "typeNameFull" })} */
                  />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={
                    <>
                      {translate("username")}
                      <Tooltip
                        color="#ffffff"
                        title={intl.formatMessage({
                          id: "tooltipMessage.username",
                        })}
                        //title={translate("whatsappInfo")}"
                      >
                        <Icon name="TooltipInfo" style={{ marginLeft: 5 }} />
                      </Tooltip>
                    </>
                  }
                  name="username"
                  rules={[{ required: true }, validateUsername]}
                  normalize={(e) => e.toLowerCase()}
                  validateTrigger="onBlur"
                >
                  <InputMask
                    mask="aaaaaaaaaaaaaaaaaaaaaaaaa"
                    maskChar={null}
                    className="input-general"
                    autoComplete="off"
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={12} xs={24} md={12}>
                <div className={styles.labelNickname}>
                  <label className={cx("scorpion-main", styles.customLabel)}>
                    {translate("nickname")}
                  </label>
                  {/* <label
                    className={cx("i scorpion-main", styles.customLabelTwo)}
                  >
                    {translate("optional")}
                  </label> */}
                  <Tooltip
                    className={styles.customLabel}
                    color="#ffffff"
                    title={intl.formatMessage({
                      id: "tooltipMessage.nickname",
                    })}
                  >
                    <Icon name="TooltipInfo" />
                  </Tooltip>
                </div>

                <Form.Item
                  tooltip
                  name="nickname"
                  rules={[validateNickname]}
                  validateTrigger="onBlur"
                >
                  <Input className="input-general" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("dateOfBirth")}
                  name="dateOfBirth"
                  rules={[{ required: true }, validateDate]}
                  validateTrigger="onBlur"
                >
                  <InputMask
                    mask="99/99/9999"
                    maskChar={null}
                    className="input-general"
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={12} xs={24} md={12} className={styles.documentPadding}>
                <Form.Item
                  label={translate("documentNumber")}
                  name="documentNumberCPF"
                  rules={[{ required: true }, validateDocumentCPF]}
                  validateTrigger="onBlur"
                >
                  <InputMask
                    mask="999.999.999-99"
                    maskChar={null}
                    className="input-general"
                    /* placeholder={intl.formatMessage({ id: "typeDocument" })} */
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("password")}
                  name="newPassword"
                  validateFirst
                  validateStatus={"error"}
                  rules={[
                    {
                      type: "string",
                      required: true,
                      min: 6,
                    },
                    {
                      pattern:
                        /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
                      message: intl.formatMessage({ id: "passwordError" }),
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                >
                  <Input.Password
                    // onChange={(event) => progressCount(event.target.value)}
                    iconRender={(isVisible) =>
                      isVisible ? (
                        <Icon name="EyeOpen" />
                      ) : (
                        <Icon name="EyeBlocked" />
                      )
                    }
                  />
                </Form.Item>

                {/* <div className="flex flex-row">
                  <Progress
                    className="mb16"
                    showInfo={false}
                    percent={progress}
                    status={progressBar[progress].color}
                  />

                  <span className="dusty-gray ml12">
                    {progressBar[progress].text}
                  </span>
                </div> */}
              </Col>
              {/* <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("identityDocument")}
                  name="identityDocument"
                >
                  <Select
                    allowClear
                    className="select-document"
                    placeholder={intl.formatMessage({ id: "selectDocument" })}
                    value={documentType}
                    onChange={handleChangeIdentityDocument}
                    disabled
                  >
                    <Select.Option value="CPF">CPF</Select.Option>
                    <Select.Option value="Passaporte">
                      {intl.formatMessage({ id: "passport" })}
                    </Select.Option>
                    <Select.Option value="Outros">
                      {intl.formatMessage({ id: "others" })}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>

            {/* {documentType === "Outros" ? (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col
                  span={12}
                  xs={24}
                  md={12}
                  className={styles.documentPadding}
                >
                  <Form.Item
                    label={translate("whatDocument")}
                    name="whatDocument"
                    rules={[{ required: true }]}
                    validateTrigger="onBlur"
                  >
                    <Input className="input-general" />
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} md={12}>
                  <Form.Item
                    label={translate("documentNumber")}
                    name="documentNumberOthers"
                    rules={[{ required: true }]}
                  >
                    <Input
                      className="input-general"
                      placeholder={intl.formatMessage({ id: "typeDocument" })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : null} */}

            {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("password")}
                  name="newPassword"
                  validateFirst
                  validateStatus={"error"}
                  rules={[
                    {
                      type: "string",
                      required: true,
                      min: 6,
                    },
                    {
                      pattern:
                        /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
                      message: intl.formatMessage({ id: "passwordError" }),
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                >
                  <Input.Password
                    onChange={(event) => progressCount(event.target.value)}
                    iconRender={(isVisible) =>
                      isVisible ? (
                        <Icon name="EyeOpen" />
                      ) : (
                        <Icon name="EyeBlocked" />
                      )
                    }
                  />
                </Form.Item>

                <div className="flex flex-row">
                  <Progress
                    className="mb16"
                    showInfo={false}
                    percent={progress}
                    status={progressBar[progress].color}
                  />

                  <span className="dusty-gray ml12">
                    {progressBar[progress].text}
                  </span>
                </div>
              </Col>

              <Col
                span={12}
                xs={24}
                md={12}
                className={styles.confirmPassawordPadding}
              >
                <Form.Item
                  label={translate("repeatPassword")}
                  name="repeatPassword"
                  validateStatus={"error"}
                  rules={[{ required: true }, validateConfirmPassword]}
                >
                  <Input.Password
                    placeholder={intl.formatMessage({ id: "repeatPassword" })}
                    iconRender={(isVisible) =>
                      isVisible ? (
                        <Icon name="EyeOpen" />
                      ) : (
                        <Icon name="EyeBlocked" />
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row> */}

            {mobile ? (
              <div className={cx("mb24 uploadAvatar", { mt24: isMobile })}>
                <Col span={12} xs={24} md={24}>
                  <Form.Item name="avatar" rules={[{ required: true }]}>
                    <div
                      className={
                        documentType !== "Outros"
                          ? cx("tc", styles.photoContainer)
                          : cx("tc", styles.photoContainerTwo)
                      }
                    >
                      <span className="f20 fw5 mine-shaft">
                        {translate("profilePhoto")}
                      </span>
                      <div>
                        <span className="f14 normal scorpion-main">
                          {translate("choosePhoto")}
                        </span>
                      </div>
                      <div className="flex justify-center">
                        {photo ? (
                          <Avatar src={photo} className={styles.photoAvatar} />
                        ) : (
                          <div
                            className={cx(
                              "bg-kimberly mt12 mb12",
                              styles.photoEmpty
                            )}
                          />
                        )}
                      </div>
                      <div>
                        <Upload
                          ref={uploadRef}
                          showUploadList={false}
                          onChange={handlePreviewPhoto}
                          customRequest={({ onSuccess }) => {
                            setTimeout(() => {
                              onSuccess("ok")
                            }, 0)
                          }}
                        >
                          <Button type="primary" className="upload-photo">
                            {photo
                              ? translate("changePhoto")
                              : translate("upload")}
                          </Button>
                        </Upload>
                      </div>
                      <p
                        className={styles.flexAvatar}
                        onClick={() => setVisible(true)}
                      >
                        <span className="f14 normal scorpion-main pointer">
                          {translate("or")}
                        </span>
                        <p className={styles.orUseAvatar}>
                          <a className={cx("f14 fw6 mt12 ")}>
                            {translate("avatarUse")}
                          </a>
                        </p>
                      </p>
                    </div>
                  </Form.Item>
                </Col>
              </div>
            ) : null}

            <Row className={(styles.center, styles.buttonMarginTopTwo)}>
              <Button
                type="primary"
                className="continue-registration"
                htmlType="submit"
                onClick={() => setLoadingButton(true)}
                loading={loadingButton}
              >
                {translate("createAccount")}
              </Button>
            </Row>
          </Col>
        </div>
      </Form>

      {visible ? (
        <AvatarUser
          visible={visible}
          handleClose={() => setVisible(false)}
          handleAvatar={handleAvatarChoose}
        />
      ) : null}

      {visibleValidatePhoneNumber ? (
        <CustomModal
          visible={visibleValidatePhoneNumber}
          className="validate-phone"
          centered={tablet}
          closable={false}
          handleClose={() => {
            setVisibleValidatePhoneNumber(false)
          }}
        >
          <Row
            justify="center"
            className={cx(
              "pt48 pb48 forgotPasswordContext",
              styles.verificationNumber
            )}
          >
            <AuthStep
              handleResetStep={handleResetStep}
              phoneNumber={phoneNumber}
              loading={loading}
              setLoading={setLoading}
              setErrors={setErrors}
              onlyValidate
              handleSuccessOnlyValidate={handleSuccessOnlyValidate}
              handleErrorOnlyValidate={handleErrorOnlyValidate}
            />
          </Row>
        </CustomModal>
      ) : null}

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}

      {successMessage ? (
        <SuccessModalStylized
          visible={!!successMessage}
          description={successMessage}
          handleClose={() => setSuccessMessage(null)}
          buttonText={intl.formatMessage({ id: "continue" })}
          modalOption="tokenConfirmed"
        />
      ) : null}
    </>
  )
}

export default RegistrationUser
