import { CustomModalErrors } from "@components/CustomModalErrors"
import { SuccessModal } from "@components/SuccessModal"
import translate from "@src/i18n"
import { getOnlyDigits } from "@utils/strings"
import { Button, Col, Form, Input, Row, Select } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const RegistrationAddress = (props) => {
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState(null)

  const intl = useIntl()

  const [form] = Form.useForm()

  const mobile = useMedia({ minWidth: 150, maxWidth: 1023 })
  const tabletDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 })
  const desktop = useMedia({ minWidth: 1920 })

  const {
    handlePageSelected,
    states,
    cities,
    handleGetAllCities,
    handleSearchAddressViaCep,
    addressViaCep,
    registerFormValues,
    selectedPlan,
  } = props

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }

  useEffect(() => {
    if (
      registerFormValues.address &&
      form.getFieldValue("zipcode") === registerFormValues.address.zipcode
    ) {
      return
    }

    if (addressViaCep && addressViaCep.error) {
      clearFields()
      return
    }

    if (addressViaCep && !addressViaCep.error) {
      const street = _get(addressViaCep, "logradouro", "")
      const district = _get(addressViaCep, "bairro", "")
      const city = _get(addressViaCep, "localidade", "")
      const state = _get(addressViaCep, "uf", "")

      form.setFieldsValue({
        street,
        district,
        city,
        state,
      })
    }
  }, [addressViaCep])

  const handleChangeState = (value) => {
    handleGetAllCities(value)
  }

  const handleBlurZipCode = (e) => {
    const value = e.target.value

    if (value.length < 9) return

    handleSearchAddressViaCep(value)
  }

  const handleFinishForm = (values) => {
    handlePageSelected({
      nextStepNumber: 2,
      values: {
        ...registerFormValues,
        address: {
          street: values.street,
          number: values.number,
          complement: values.complement,
          district: values.district,
          city: values.city,
          state: values.state,
          country: values.country,
          zipcode: getOnlyDigits(values.zipcode),
        },
      },
      plan: selectedPlan,
    })
  }

  const validateZipCode = () => ({
    validator(rule, value) {
      if (value) {
        if (
          registerFormValues.address &&
          value === registerFormValues.address.zipcode
        ) {
          return Promise.resolve()
        }

        if (value.length < 9 || (addressViaCep && addressViaCep.error)) {
          return Promise.reject(intl.formatMessage({ id: "errorZipCode" }))
        }
      }
      return Promise.resolve()
    },
  })

  const clearFields = () => {
    form.setFieldsValue({
      street: "",
      complement: "",
      district: "",
      city: "",
      state: "",
    })
  }

  const zipCode = _get(addressViaCep, "cep", "")

  return (
    <>
      <Form
        form={form}
        className={styles.formWidth}
        layout="vertical"
        validateMessages={VALIDATE_MESSAGES}
        onFinish={handleFinishForm}
        autoComplete="off"
        initialValues={{
          country: "BRA",
          ...registerFormValues.address,
        }}
      >
        <div
          className={cx(
            "registration-general flex flex-row justify-center",
            styles.containerRegistration
          )}
        >
          <Col
            span={12}
            xs={24}
            md={mobile ? 24 : tabletDesktop ? 14 : desktop ? 12 : 12}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("zipcode")}
                  name="zipcode"
                  rules={[{ required: true }, validateZipCode]}
                >
                  <InputMask
                    mask="99999-999"
                    maskChar={null}
                    /*  placeholder={intl.formatMessage({
                      id: "placeholderZicode",
                    })} */
                    className="input-general"
                    onBlur={handleBlurZipCode}
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("street")}
                  name="street"
                  rules={[{ required: true }]}
                >
                  <Input
                    className="input-general"
                    /*  placeholder={intl.formatMessage({ id: "typeStreet" })} */
                  />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("number")}
                  name="number"
                  rules={[{ required: true }]}
                >
                  <InputMask
                    mask="99999999"
                    maskChar={null}
                    /*  placeholder={intl.formatMessage({
                      id: "typeNumber",
                    })} */
                    className="input-general"
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item label={translate("complement")} name="complement">
                  <Input
                    className="input-general"
                    /*  placeholder={intl.formatMessage({ id: "typeComplement" })} */
                  />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("district")}
                  name="district"
                  rules={[{ required: true }]}
                >
                  <Input
                    className="input-general"
                    /* placeholder={intl.formatMessage({ id: "typeDistrict" })} */
                  />
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("state")}
                  name="state"
                  rules={[{ type: "string", required: true }]}
                >
                  <Select
                    className="select-document"
                    showSearch
                    onChange={handleChangeState}
                    disabled={zipCode}
                    /* placeholder={intl.formatMessage({
                      id: "selectStateBrazil",
                    })} */
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {_map(states, (item) => (
                      <Select.Option key={item.id} value={item.sigla}>
                        {item.sigla}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("city")}
                  name="city"
                  rules={[{ type: "string", required: true }]}
                >
                  <Select
                    className="select-document"
                    showSearch
                    disabled={zipCode || !form.getFieldValue("state")}
                    /* placeholder={intl.formatMessage({ id: "selectCityBrazil" })} */
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {_map(cities, (item) => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} xs={24} md={12}>
                <Form.Item
                  label={translate("country")}
                  name="country"
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    className="select-document"
                    /* placeholder={intl.formatMessage({ id: "selectCountry" })} */
                    disabled
                  >
                    <Select.Option value="BRA">
                      {intl.formatMessage({ id: "brazil" })}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row className={(styles.center, styles.buttonMarginTop)}>
              <Button
                type="primary"
                className="continue-registration"
                htmlType="submit"
              >
                {translate("continue")}
              </Button>
            </Row>
          </Col>
        </div>
      </Form>

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}

      {successMessage ? (
        <SuccessModal
          visible={!!successMessage}
          description={successMessage}
          handleClose={() => setSuccessMessage(null)}
          buttonText={intl.formatMessage({ id: "continue" })}
        />
      ) : null}
    </>
  )
}

export default RegistrationAddress
